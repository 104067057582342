/* eslint-disable no-undef */ /* eslint-disable no-unused-vars */ /*
eslint-disable no-unused-vars */ /* eslint-disable no-irregular-whitespace */
<template>
	<div class="courseDetails">
		<div class="c_D_back">
			<div class="plate-center">
				<div class="plate-center-photo" :class="{ 'plate-center-photos': isVideo }">
					<div>
						<video ref="refVideo" id="video" autoplay class="video"></video>
						<canvas width="200px" height="200px" ref="refCanvas" :style="{ opacity: 0 }" class="canvas"></canvas>
					</div>
				</div>
				<div class="c_D_back_title">
					<p><span>学习中心</span>>课程详情</p>
				</div>
				<div class="c_D_back_video">
					<video id="videos" class="yh-w100 video-js vjs-default-skin" controls preload controlslist="nodownload"
						@ended="videoEnd" @play="play" @pause="pause" @timeupdate="ontimeupdate">
						<source :src="currentVideo.video" type="application/x-mpegURL" />
						<source :src="currentVideo.video" type="video/mp4" />
						<source :src="currentVideo.video" type="video/webm" />
						<source :src="currentVideo.video" type="video/ogg" />
					</video>
					<!-- <div class="c_D_video_con">
            <div class>
              <img
                v-if="!playing"
                class="yh-img26 yh-cp"
                src="../assets/image/Playicon.png"
                alt
              />
              <img
                v-if="playing"
                class="yh-img26 yh-cp"
                src="../assets/image/zj-zant.png"
                alt
              />
            </div>
          </div> -->
				</div>
			</div>
		</div>
		<!--  -->
		<div class="plate-center">
			<div>
				<h3 class="yh-lh64">{{ info.course_name || "" }}</h3>
				<div class="flex flex_between">
					<div class="flex">
						<p>
							学时:
							<span class="yh-fc-blue yh-ml10 yh-mr20">{{ info.hours || 0 }} 学时</span>
						</p>
						<p>
							学习时间:
							<span class="yh-fc-blue">{{
								info.time || ""
							}}</span>
						</p>
					</div>
					<div>
						学习进度:
						<span class="yh-fc-blue">{{ info.task }} %</span>
					</div>
				</div>
			</div>

			<div class="yh-h72 yh-bcF5 yh-mt30 level_align yh-fc333 yh-fz20">
				<div class="flex-1 center_combo yh-h100 yh-cp" @click="classify = 0" :class="{ classifyAct: classify == 0 }">
					<span>课程详情</span>
				</div>
				<div class="flex-1 center_combo yh-h100 yh-cp" @click="classify = 1" :class="{ classifyAct: classify == 1 }">
					<span>简介</span>
				</div>
			</div>

			<!-- 目录列表 -->
			<div class="yh-pd20 yh-border1" v-show="classify == 0">
				<el-collapse accordion v-model="activeNames" @change="changeCollapse">
					<!-- :disabled="it.flag" -->
					<el-collapse-item :name="it.chapter_id" v-for="(it, ind) in list" :key="ind" :class="{
						actChapter:
							currentChapter.chapter_id == it.chapter_id
					}">
						<template slot="title">
							<div class="level_align flex_between yh-w100 yh-ptb0lr10">
								{{ it.name }}
								<span class="yh-fc999" v-if="it.task == 100">已学完</span>
								<span class="yh-fc666" v-if="it.task != 0 && it.task != 100">已学 {{ it.task }}%</span>
							</div>
						</template>
						<ul class="yh-pd10">
							<li v-for="(item, index) in listItem" :key="index" @click="chagneVideo(item, it, ind, index)"
								@dblclick="chagnesVideo(item, it, index)" class="level_align flex_between yh-lh30">
								<div>
									<span class="yh-mr20" :class="{
										'yh-fc-blue':
											currentSelectVideo.video_id ==
											item.video_id
									}">●</span>
									<span :class="{
										'yh-fc-blue':
											currentSelectVideo.video_id ==
											item.video_id
									}">{{ item.title }}</span>
								</div>
								<div v-if="currentSelectVideo.video_id !=
									item.video_id &&
									currentVideo.video_id !=
									item.video_id
									">
									已学 {{ item.video_task }}%
								</div>
								<img v-if="currentSelectVideo.video_id ==
									item.video_id &&
									(currentVideo.video_id !=
										item.video_id ||
										!playing)
									" @click.stop="playVideo(item, it, index)" class="yh-img20 yh-cp" src="../assets/image/Playicon.png" alt />
								<img v-if="playing &&
									currentVideo.video_id ==
									item.video_id
									" class="yh-img20 yh-cp" src="../assets/image/zj-zant.png" alt />
							</li>
						</ul>
					</el-collapse-item>
				</el-collapse>
			</div>
			<!-- 简介 -->
			<div class="yh-pd20" v-show="classify == 1" v-html="info.introduction"></div>
		</div>
		<!-- 人脸验证 -->
		<Videoface :video="video" :video_id="currentVideo.video_id" :movieid="movieid" @nextok="nextok" @nextoks="nextoks">
		</Videoface>
		<div class="topic" v-if="isTopic">
			<div class="topic_close" @click="closeTopic">
				<img src="../assets/image/guanbi.png" alt="" />
			</div>
			<div class="topic_main">
				<div class="topic_main_name">{{ topicMesage.name }}</div>
				<ul class="topic_main_list">
					<li v-for="(item, index) in topicMesage.timu" :class="{ active: index == topicIndex }"
						@click="changeTopic(index)" :key="index">
						{{ item.value }}
					</li>
				</ul>
				<div class="topic_main_footer" v-if="topicIndex + 1">
					<div class="topic_main_daan">
						<span>答案：</span>
						<span>{{ topicMesage.daan }}</span>
					</div>
					<div class="topic_main_jiexi">{{ topicMesage.jiexi }}</div>
				</div>
				<!-- <div class="topic_main_btn">
          <div class="topic_main_btn_li" @click="closeTopic" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '上一题' : '下一题' }}</div>
        </div> -->
			</div>
		</div>
		<div class="marking" v-if="isTopic"></div>
	</div>
</template>

<script>
var track = null;
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Videoface from "../components/videoface.vue";
import $DB from "../utils/debounce";
export default {
	components: {
		Videoface
	},
	name: "courseDetails",
	data() {
		return {
			isTopic: false,
			muted: true,
			activeNames: "", //是否展开
			/* 视频人脸认证 */
			video: {
				videosfacefalg: false,
				recognition: true,
				detection: false
			},
			classify: 0, // 0 课程目录 1简介
			playing: false, // 播放中
			course_id: "", // 课程id
			info: {}, // 课程详情
			list: [], // 章节列表
			chapterInfo: {}, // 章节详情
			listItem: [], // 章节视频列表
			// currentSelectChapter: {}, // 当前选中的章节
			currentChapter: {}, // 当前播放的章节

			currentSelectVideo: {}, // 当前选中的视频
			currentVideo: {}, // 当前播放的视频
			maxDragProgress: null, //最大可拖动进度
			interval: null, //监听上传的定时器
			suspend: null, //禁止拖动
			video_real_time: 0, //实时播放进度 不能快进 视频进度到哪里了
			nextNode: {}, //播放节点
			// eslint-disable-next-line vue/no-dupe-keys
			movieid: "",
			videoIndex: null, //当前那个章节
			videosIndex: null, //那一章节的那一节
			removePhotoID: null, //拍照的定时器
			formData: {}, //人脸需要上传的文件
			isVideo: false,
			timeOut: null,
			player: "", //video.js播放器
			playerMobile: "",
			options: {
				controls: true,
				controlBar: {
					playToggle: true,
					currentTimeDisplay: true,
					progressControl: true,
					durationDisplay: true,
					remainingTimeDisplay: true,
					playbackRateMenuButton: false,
					volumePanel: true,
					fullscreenToggle: false
				}
			},
			topicMesage: {}, //
			topicIndex: -1,
			start_time: "", //开始时间
			end_time: "", //结束时间
			screenTime: null,//暂停定时器
			isOpen: 0,//0关1开
		};
	},
	created() {

		// this.$message({
		// 	message: "每天线上学习时间不超过8小时，超过后学时不累加",
		// 	type: "warning",
		// 	duration: 5000
		// });
		this.delInterval();
		this.course_id = this.$route.query.id;
		this.getInfo();
		this.$axios.post(this.$api.limitation).then(res => {
			console.log(res.data)
			this.isOpen = res.data
		})
	},
	mounted() {
		window.addEventListener("beforeunload", e => {
			this.beforeunloadHandler(e);
		});
	},
	destroyed() {
		if (this.start_time) {
			this.end_time = this.getTime();
			this.submitLocus(this.currentVideo);
		}
		this.player.dispose()
		window.removeEventListener("beforeunload", e => {
			this.beforeunloadHandler(e);
		});
	},
	methods: {
		//获取年月日
		getTime() {
			let date = (new Date() * 1) / 1000;
			return date;
		},
		//是否关闭网页
		beforeunloadHandler(e) {
			e = e || window.event;
			if (this.start_time) {
				this.end_time = this.getTime();
				this.submitLocus(this.currentVideo);
			}
			if (e) {
				e.returnValue =
					"您是否确认离开此页面-您输入的数据可能不会被保存";
			}
			return "您是否确认离开此页面-您输入的数据可能不会被保存";
		},
		//提交轨迹
		submitLocus(item) {
			let time = this.end_time - this.start_time;
			if (time <= 2 && this.start_time) return;
			if (item.is_kan == 0) {
				clearTimeout(track);
				track = setTimeout(() => {
					this.$axios
						.post(this.$api.locus, {
							start_time: this.start_time,
							end_time: this.end_time,
							video_id: item.video_id,
							duration: time
						})
						.then(() => {
							this.start_time = Number(this.getTime()) + 1;
						});
				}, 1500);
			} else {
				this.start_time = "";
			}
		},
		closeTopic() {
			if (!this.topicIndex == -1) return;
			// if (this.topicMesage.timu[this.topicIndex].label != this.topicMesage.daan) return
			this.isTopic = false;
		},
		changeTopic(index) {
			if (this.topicIndex != -1) return;
			this.topicIndex = index;
			this.$axios
				.post("/api/index/timuRecord", {
					course_id: this.course_id,
					video_id: this.currentVideo.video_id,
					list_id: this.topicMesage.id,
					answer: this.topicMesage.timu[this.topicIndex].label,
					is_right:
						this.topicMesage.timu[this.topicIndex].label !=
							this.topicMesage.daan
							? 0
							: 1
				})
				.then(res => {
					if (res.code === 1) {
						this.topicIndex = -1;
						this.isTopic = false;
					}
				});
		},
		getTopic() {
			this.isTopic = true;
			this.$axios
				.post("/api/index/timu", {
					course_id: this.course_id,
					chapter_id: this.activeNames
				})
				.then(res => {
					this.topicMesage = res.data;
				});
		},
		//视频播放结束
		videoEnd() {
			if (this.start_time) {
				this.end_time = this.getTime();
				this.submitLocus(this.currentVideo);
			}

			this.list.map((item, index) => {
				if (index == this.videoIndex) {
					if (index < this.list.length) {
						this.activeNames = this.list[index].chapter_id;
					}
				}
			});
			this.listItem.map((item, index) => {
				if (index == this.videosIndex) {
					if (index < this.listItem.length) {
						if (this.player) {
							this.player.pause();
							if (this.listItem[index + 1]) {
								this.currentVideo = this.listItem[index + 1];
								this.currentSelectVideo = this.listItem[
									index + 1
								];
								if (this.listItem[index + 1].video_type == 1) {
									this.player.src({ src: this.listItem[index + 1].video, type: "video/mp4" });
								} else {
									this.player.src({
										src: this.listItem[index + 1].video,
										type: "application/x-mpegURL"
									});
								}
								this.player.src({
									src: this.listItem[index + 1].video,
									type: "video/webm"
								});
								this.player.src({
									src: this.listItem[index + 1].video,
									type: "video/ogg"
								});
								if (this.listItem[index + 1].video_type != 1) {
									this.player.src({ src: this.listItem[index + 1].video, type: "video/mp4" });
								} else {
									this.player.src({
										src: this.listItem[index + 1].video,
										type: "application/x-mpegURL"
									});
								}
								this.player.load({
									src: this.listItem[index + 1].video
								});
								this.player.currentTime(
									this.listItem[index + 1].task_time
								);
								this.player.play();
							} else {
								let num = this.list.findIndex(
									item => item.chapter_id == this.activeNames
								);
								if (num < this.list.length - 1) {
									this.activeNames = this.list[
										num + 1
									].chapter_id;
									this.$axios
										.post(this.$api.chapterDetail, {
											chapter_id: this.activeNames
										})
										.then(res => {
											if (res.code === 1) {
												this.chapterInfo = res.data;
												this.listItem = res.data.video;
												this.currentVideo =
													res.data.video[0];
												this.player.src({
													src: this.currentVideo
														.video,
													type: "video/mp4"
												});
												this.player.src({
													src: this.currentVideo
														.video,
													type: "video/webm"
												});
												this.player.src({
													src: this.currentVideo
														.video,
													type: "video/ogg"
												});
												this.player.src({
													src: this.currentVideo
														.video,
													type:
														"application/x-mpegURL"
												});
												this.player.load({
													src: this.currentVideo.video
												});
												this.player.currentTime(
													this.currentVideo.task_time
												);
												this.player.play();
											}
										});
								}
							}

							return;
						}
						// let video = document.getElementById("videos");
						this.playing = true;
						// video.load();
						// video.currentTime = item.task_time;
						// this.$forceUpdate();
					} else {
						if (this.videoIndex != this.list.length) {
							this.activeNames = this.list[
								this.videoIndex + 1
							].chapter_id;
							// this.currentVideo = this.listItem[index + 1];
							// let video = document.getElementById("videos");

							// video.load();
							// video.currentTime = item.task_time;
							// this.$forceUpdate();
						}
					}
				}
			});
		},
		// 人脸识别代码
		//开始识别
		playVideos() {
			this.getUserMedia(
				{
					video: {
						width: 320,
						height: 320,
						facingMode: "user"
					} /* 前置优先 */
				},
				this.success,
				this.error
			);
			this.$axios.post(this.$api.jian_10, {
				video_id: this.currentVideo.video_id
			})
		},
		// // 人脸捕捉
		initTracker() {

			this.context = this.$refs.refCanvas.getContext("2d"); // 画布
			// this.removePhotoID = setInterval(() => {
			// 	this.tackPhoto();
			// 	this.tipFlag = true;
			// }, 3000);
			this.tackPhoto();
			this.tipFlag = true;

		},
		// // 访问用户媒体设备
		getUserMedia(constrains, success, error) {
			if (navigator.mediaDevices.getUserMedia) {
				//最新标准API
				navigator.mediaDevices
					.getUserMedia(constrains)
					.then(success)
					.catch(error);
			} else if (navigator.webkitGetUserMedia) {
				//webkit内核浏览器
				navigator
					.webkitGetUserMedia(constrains)
					.then(success)
					.catch(error);
			} else if (navigator.mozGetUserMedia) {
				//Firefox浏览器
				navigator
					.mozGetUserMedia(constrains)
					.then(success)
					.catch(error);
			} else if (navigator.getUserMedia) {
				//旧版API
				navigator
					.getUserMedia(constrains)
					.then(success)
					.catch(error);
			} else {
				this.$message({
					message: "你的浏览器不支持访问用户媒体设备",
					type: "warning"
				});
			}
		},
		success(stream) {
			this.streamIns = stream;
			// webkit内核浏览器
			this.URL = window.URL || window.webkitURL;
			if ("srcObject" in this.$refs.refVideo) {
				this.$refs.refVideo.srcObject = stream;
			} else {
				this.$refs.refVideo.src = this.URL.createObjectURL(stream);
			}

			this.$refs.refVideo.onloadedmetadata = () => {
				this.$refs.refVideo.play();
				this.initTracker();
			};
		},
		error() {
			console.log("00000");
		},
		// // 拍照
		tackPhoto() {
			this.context.drawImage(
				this.$refs.refVideo,
				0,
				0,
				this.context.canvas.width,
				this.context.canvas.height
			);
			// 保存为base64格式
			this.imgUrl = this.saveAsPNG(this.$refs.refCanvas);
			this.flag = false;
			this.tipFlag = false;
			/** 拿到base64格式图片之后就可以在this.compare方法中去调用后端接口比较了，也可以调用getBlobBydataURI方法转化成文件再去比较
			 * 我们项目里有一个设置个人头像的地方，先保存一下用户的图片，然后去拿这个图片的地址和当前拍照图片给后端接口去比较。
			 * */
			this.compare(this.imgUrl);
		},
		// Base64转文件
		getBlobBydataURI(dataURI, type) {
			var binary = window.atob(dataURI.split(",")[1]);
			var array = [];
			for (var i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], {
				type: type
			});
		},
		compare(url) {
			let blob = this.getBlobBydataURI(url, "image/png");
			this.uploadImg(blob);

			// baiduFaceCheck({ image: url.split(",")[1], image_type: "BASE64" }).then(
			//   res => {
			//     if (res.data.code === 1) {
			//       if (JSON.parse(res.data.data).error_msg === "SUCCESS") {
			//
			//       } else {
			//         this.notcamerafalg = true;
			//         this.close();
			//       }
			//     }
			//   }
			// );
		},
		//s上传图片
		uploadImg(blob) {
			let formData = new FormData();
			formData.append(
				"image",
				blob,
				"file_" + Date.parse(new Date()) + ".png"
			);
			formData.append("token", sessionStorage.getItem("token"));
			formData.append("video_id", this.currentVideo.video_id);
			this.$axios.post(this.$api.faceAuth, formData).then(res => {
				if (res.code === 1) {
					if (res.data.status == 1) {
						this.close();
						clearTimeout(this.removePhotoID);
						this.removePhotoID = null;
						this.isVideo = false;
						clearTimeout(this.timeOut);
						this.playVideotime();
						this.timeOut = null;
						this.$message({
							message: res.msg,
							type: "success"
						});
						// this.camerafalg = !this.camerafalg;
						// this.close();
					}
				}
			});
		},
		// // 保存为png,base64格式图片
		saveAsPNG(c) {
			return c.toDataURL("image/png", 0.3);
		},

		// // 关闭并清理资源
		close() {
			this.isVideo = false;
			this.flag = false;
			this.tipFlag = false;
			this.showContainer = true;
			this.tracker &&
				this.tracker.removeListener("track", this.handleTracked);
			this.tracker = null;
			this.context = null;
			clearTimeout(this.removePhotoID);
			this.removePhotoID = null;
			if (this.streamIns) {
				this.streamIns.enabled = false;
				this.streamIns.getTracks()[0].stop();
				this.streamIns.getVideoTracks()[0].stop();
			}
			this.streamIns = null;
			// this.playVideo()
		},
		// 人脸识别代码
		//人脸识别成功下一步操作按钮
		nextok() {
			// this.supers();
			this.startfalg = false;
			this.video.videosfacefalg = false;
			this.mask = false;
			this.upTask();
			if (this.player) {
				this.player.play();
			}
			// this.interval = setInterval(() => {
			//   this.upTask();
			// }, 3000);
		},
		//子组件取消按钮
		nextoks(item) {
			this.video.videosfacefalg = false;
			this.recognition = item;
			this.taskBack();
		},
		//视频回退
		taskBack() {
			this.$axios
				.post(this.$api.taskBack, {
					video_id: this.currentVideo.video_id
				})
				.then(res => {
					if (res.code === 1) {
						// let video = document.getElementById("videos");
						// video.currentTime = parseInt(video.currentTime) - 15;
						this.player.currentTime(
							parseInt(this.player.currentTime()) - 15
						);
						this.player.play();
						//  this.getInfo();
						// this.golobalBack();
					}
				});
		},
		//暂停
		pause() {
			this.delInterval();

			if (!this.video.videosfacefalg) {
				this.upTask();
			}
			clearInterval(this.screenTime)
			this.screenTime = setInterval(() => {
				if (this.start_time) {
					clearInterval(this.screenTime)
					this.end_time = this.getTime();
					this.submitLocus(this.currentVideo);
				}
			}, 300000)
		},
		ontimeupdate() {
			// let video = document.getElementById("videos");
			var isReady;
			if (this.isOpen == 1) {
				if (this.currentVideo.duration <= this.currentVideo.task_time) {
					// 观看的进度大于视频的长度，不用在限制
					isReady = 0;
				} else {
					isReady = 1;
				}
			} else {
				isReady = null;
			}

			// debugger
			// //跳转到指定播放位置 initial-time 时间为秒
			let _this = this;
			// //播放的总时长
			// var duration = e.target.currentTime
			// //实时播放进度 秒数
			var currentTime = this.player.currentTime();
			// //当前视频进度    查看正在播放时间，以秒为单位
			// console.log("视频播放到第" + currentTime + "秒")

			// let videoContext1 = wx.createVideoContext('myVideo')
			if (isReady == 1 && _this.currentVideo.video_task != 100) {
				if (
					currentTime > this.maxDragProgress &&
					currentTime - this.maxDragProgress > 12
				) {
					// 只能拖动到记录的最大值

					this.maxDragProgress
						? this.player.currentTime(this.maxDragProgress)
						: this.player.currentTime(currentTime);


				} else {
					this.video_real_time = currentTime; //实时播放进度

				}
				//
			} else if (isReady == 0) {

				//修改之后
				this.player.currentTime(this.maxDragProgress);

			} else {

				_this.video_real_time = currentTime; //实时播放进度
			}
			// eslint-disable-next-line no-irregular-whitespace
			if (this.isOpen == 1) {
				if (
					_this.nextNode &&
					_this.nextNode.time &&
					_this.currentVideo.video_task != 100
				) {
					if (
						Math.floor(_this.video_real_time) ==
						Number(_this.nextNode.time)
					) {

						this.isVideo = true;

						_this.useStopPlay(); // 非全屏时识别
						clearTimeout(this.faceTime)
						this.faceTime = setTimeout(() => {
							this.playVideos();
						}, 1000)
					}

				}
			}

		},
		// 让视频暂停，进行人脸识别
		useStopPlay: $DB(function () {
			// this.upTask();
			this.delInterval();

			this.isVideo = true;

			this.timeOut = setTimeout(() => {
				this.close();
				clearInterval(this.removePhotoID);
				this.removePhotoID = null;
				this.isVideo = false;
				this.video.videosfacefalg = true;
				console.log('object');
				this.$axios.post(this.$api.jian_10, {
					video_id: this.currentVideo.video_id
				})
				this.enableAutoRotation = false;
				// let video = document.getElementById("videos");
				// video.pause();
				this.player.pause();
				this.$nextTick(() => {
					this.isface = true;
				});
			}, 8000);
		}),
		//监听播放
		play() {
			// 监听进度提交
			this.delInterval();
			// this.upTask();
			clearInterval(this.screenTime)
			this.start_time ? "" : (this.start_time = this.getTime());
			this.interval = setInterval(() => {
				this.upTask();
			}, 3000);
		},
		playVideotime: $DB(function () {
			this.upTask();
			this.delInterval();
			this.interval = setInterval(() => {
				this.upTask();
			}, 3000);
		}),
		//清除定时器
		delInterval() {
			clearInterval(this.interval); // 清除计时器
			this.interval = null;
		},
		upTask() {
			// let video = document.getElementById("videos");
			this.$axios
				.post(this.$api.taskReport, {
					video_id: this.currentVideo.video_id,
					total_duration: Math.floor(this.player.duration()) || 0,
					task_time: Math.floor(this.player.currentTime()) || 0
				})
				.then(res => {
					if (res.code === 1) {
						this.nodeLength = res.data.length;
						this.nodeInfo = res.data;
						this.nextNode = res.data[0];
						// if(this.nextNode && this.nextNode.time && this.nextNode.time <= taskTime){  // 如果有下一个节点的时间，并且节点时间小于等于当前播放时间
						// 	if(this.nodeIndex <= this.nodeLength){
						// 		this.nextNode = res.data[this.nodeIndex]
						// 	}
						// }else{ // 没有节点时间

						// if (!this.faceFinish) {
						//   //  不是全部认证成功
						//   this.nextNode = res.data[this.nodeIndex];
						// } else {
						//   // 全部认证成功
						//   this.nextNode = {};
						// }
						// }
						this.$axios
							.post(this.$api.chapterDetail, {
								chapter_id: this.currentChapter.chapter_id
							})
							.then(res => {
								if (res.code === 1) {
									this.chapterInfo = res.data;
									res.data.video.map(item => {
										if (
											item.video_id ==
											this.currentVideo.video_id
										) {
											this.maxDragProgress =
												item.task_time;
										}
									});
									this.listItem = res.data.video;
								}
							});

						this.$axios
							.post(this.$api.courseDetail, {
								course_id: this.course_id,
								chapter_id: this.activeNames
							})
							.then(res => {
								if (res.code === 1) {
									res.data.chapter.map((item, index) => {
										if (
											index <
											res.data.chapter.length - 1
										) {
											res.data.chapter[index + 1].flag =
												item.task != 100 ? true : false;
										}

										if (
											res.data.last_chapter ==
											item.chapter_id
										) {
											this.currentChapter = item;

											this.videoIndex = index;
										}
									});
									this.info = res.data;
									this.list = res.data.chapter;
									// this.activeNames = res.data.last_chapter;
								}
							});
					} else {
						this.delInterval();
					}
				});
		},
		// 播放视频
		playVideo(item, it, index) {
			this.upTask();
			this.delInterval();
			if (this.isOpen == 1) {
				if (item.video_task < 100 && index != 0) {
					let num = this.listItem.findIndex(
						item => 0 < item.video_task < 100 && item.video_task != 100
					);
					if (index > num)
						return this.$message({
							message: "未播放完当前视频不能切换下一集",
							type: "warning"
						});
				}
			}

			if (this.start_time) {
				this.end_time = this.getTime();
				this.submitLocus(this.currentVideo);
			}
			this.activeNames = it.chapter_id
			this.currentChapter = it;
			this.currentVideo = item;

			this.muted = true;

			this.playing = true;
			this.maxDragProgress = item.task_time;

			if (this.player) {
				this.player.pause();

				if (item.video_type == 1) {
					this.player.src({ src: item.video, type: "video/mp4" });
				} else {
					this.player.src({
						src: item.video,
						type: "application/x-mpegURL"
					});
				}
				this.player.src({ src: item.video, type: "video/webm" });
				this.player.src({ src: item.video, type: "video/ogg" });

				if (item.video_type != 1) {
					this.player.src({ src: item.video, type: "video/mp4" });
				} else {
					this.player.src({
						src: item.video,
						type: "application/x-mpegURL"
					});
				}
				this.player.load({ src: item.video });
				this.player.currentTime(item.task_time);
				this.player.play();
				return;
			}
			this.$nextTick(() => {
				// eslint-disable-next-line no-undef
				this.player = videojs(
					"videos",
					this.options,
					function onPlayerReady() {
						this.currentTime(item.task_time);
						// eslint-disable-next-line no-undef
						videojs.log("播放器已经准备好了!");
						this.on("loadedmetadata", function () { });
						this.on("ended", function () { });
						this.on("error", function () {
							// this.errorDisplay.close();
						});
					}
				);
			});
		},
		// 获取章节详情
		getChapterinfo(id) {
			this.$axios
				.post(this.$api.chapterDetail, { chapter_id: id })
				.then(res => {
					if (res.code === 1) {
						this.chapterInfo = res.data;
						this.listItem = res.data.video;
						// this.maxDragProgress = this.listItem[
						//     this.videoIndex
						// ].task_time;
					}
				});
		},
		// 切换章节
		changeCollapse(e) {
			if (e) {
				if (this.isOpen == 1) {
					let index = this.list.findIndex(item => item.chapter_id == e);
					let item = this.list.filter(item => item.chapter_id == e)[0];
					let num = this.list.findIndex(
						item => 0 < item.task < 100 && item.task != 100
					);
					if (index > num && item.task != 100)
						return this.$message({
							message: "请按照顺序观看章节",
							type: "warning"
						});
				} else {
					this.currentChapter.chapter_id = e
				}


				this.getChapterinfo(e);
			}
		},
		//双击
		chagnesVideo(item, it, index) {

			// this.upTask();
			this.delInterval();
			if (this.isOpen == 1) {
				if (item.video_task < 100 && index != 0) {
					let num = this.listItem.findIndex(
						item => 0 < item.video_task < 100 && item.video_task != 100
					);
					if (index > num)
						return this.$message({
							message: "未播放完当前视频不能切换下一集",
							type: "warning"
						});
				}
			}

			if (this.start_time) {
				this.end_time = this.getTime();
				this.submitLocus(this.currentVideo);
			}
			this.activeNames = it.chapter_id
			this.currentChapter = it;
			this.currentVideo = item;
			this.muted = true;
			this.maxDragProgress = item.task_time;
			//之前
			// let video = document.getElementById("videos");
			// video.load();

			// video.currentTime = item.task_time;
			// console.log(video.currentTime)
			// this.$forceUpdate();

			//修改
			if (this.player) {
				this.player.pause();
				if (item.video_type == 1) {
					this.player.src({ src: item.video, type: "video/mp4" });
				} else {
					this.player.src({
						src: item.video,
						type: "application/x-mpegURL"
					});
				}
				this.player.src({ src: item.video, type: "video/webm" });
				this.player.src({ src: item.video, type: "video/ogg" });
				if (item.video_type != 1) {
					this.player.src({ src: item.video, type: "video/mp4" });
				} else {
					this.player.src({
						src: item.video,
						type: "application/x-mpegURL"
					});
				}
				this.player.load({ src: item.video });
				this.player.currentTime(item.task_time);
				this.player.play();
				return;
			}
			this.$nextTick(() => {
				// eslint-disable-next-line no-undef
				this.player = videojs(
					"videos",
					this.options,
					function onPlayerReady() {
						this.currentTime(item.task_time);
						// eslint-disable-next-line no-undef
						videojs.log("播放器已经准备好了!");
						this.on("loadedmetadata", function () { });
						this.on("ended", function () { });
						this.on("error", function () {
							// this.errorDisplay.close();
						});
					}
				);
			});

			// video.play();
		},
		// 切换选中的视频
		chagneVideo(item, it, int, index) {
			this.currentChapter = it;
			this.currentSelectVideo = item;
			this.videoIndex = int;
			this.videosIndex = index;
		},
		// 获取课程详情
		getInfo() {
			this.$axios
				.post(this.$api.courseDetail, { course_id: this.course_id, chapter_id: this.activeNames })
				.then(res => {
					// let timu_time = res.data.timu_time;

					if (res.code === 1) {
						res.data.chapter.map((item, index) => {
							if (index < res.data.chapter.length - 1) {
								res.data.chapter[index + 1].flag =
									item.task != 100 ? true : false;
							}

							if (res.data.last_chapter == item.chapter_id) {
								this.currentChapter = item;
								this.videoIndex = index;
							}
						});
						this.info = res.data;
						this.list = res.data.chapter;
						this.activeNames = res.data.last_chapter;

						if (res.data.last_chapter) {
							this.$axios
								.post(this.$api.chapterDetail, {
									chapter_id: res.data.last_chapter
								})
								.then(res => {
									// if (timu_time) {
									// 	setInterval(() => {
									// 		if (this.player) {
									// 			this.player.pause();
									// 		}
									// 		this.topicIndex = -1;
									// 		if (this.isOpen == 1) this.getTopic();
									// 	}, timu_time);
									// }

									if (res.code === 1) {
										this.chapterInfo = res.data;
										this.listItem = res.data.video;
										if (res.data.last_video) {
											res.data.video.map(
												(item, index) => {
													if (
														res.data.last_video ==
														item.video_id
													) {
														this.playing = true;
														this.currentVideo = item;

														this.currentSelectVideo = item;
														this.videosIndex = index;
														//新加video.js
														this.$nextTick(() => {
															// eslint-disable-next-line no-undef
															this.player = videojs(
																"videos",
																this.options,
																function onPlayerReady() {
																	this.maxDragProgress =
																		item.task_time;
																	if (
																		item.video_type ==
																		1
																	) {
																		this.src(
																			{
																				src:
																					item.video,
																				type:
																					"video/mp4"
																			}
																		);
																	} else {
																		this.src(
																			{
																				src:
																					item.video,
																				type:
																					"application/x-mpegURL"
																			}
																		);
																	}

																	this.src({
																		src:
																			item.video,
																		type:
																			"video/webm"
																	});
																	this.src({
																		src:
																			item.video,
																		type:
																			"video/ogg"
																	});
																	if (
																		item.video_type !=
																		1
																	) {
																		this.src(
																			{
																				src:
																					item.video,
																				type:
																					"video/mp4"
																			}
																		);
																	} else {
																		this.src(
																			{
																				src:
																					item.video,
																				type:
																					"application/x-mpegURL"
																			}
																		);
																	}

																	this.load({
																		src:
																			item.video
																	});
																	this.currentTime(
																		item.task_time
																	);
																	this.play();

																	// eslint-disable-next-line no-undef
																	this.on(
																		"loadedmetadata",
																		function () { }
																	);
																	this.on(
																		"ended",
																		function () { }
																	);
																	this.on(
																		"error",
																		function () {
																			this.errorDisplay.close();
																		}
																	);

																	// var last =  this.maxDragProgress,max_time=this.maxDragProgress;
																	//当目前的播放位置已更改时
																	// this.on('timeupdate', function(){
																	//     var current = this.currentTime();

																	//     if(current < max_time){
																	//       console.log(current,max_time)
																	//         console.log("进入最大值")
																	//     }else if(current - last > 2) {
																	//         this.currentTime(last);
																	//     } else {
																	//         last = current;
																	//         if(last >= max_time){
																	//             max_time=last;
																	//         }
																	//     }

																	//     if (_this.nextNode &&_this.nextNode.time &&_this.currentVideo.video_task != 100
																	//   ) {
																	//     if (Math.floor(_this.video_real_time) == Number(_this.nextNode.time)) {

																	//       this.isVideo = true
																	//       _this.playVideos()
																	//       _this.useStopPlay(); // 非全屏时识别
																	//     }
																	//   }

																	// });
																}
															);
														});
														// let video = document.getElementById("videos");
														// console.log(item.task_time)
														//  video.currentTime = item.task_time;
														// this.$nextTick(() => {
														//   this.$forceUpdate()
														//   video.play();
														// });
													}
												}
											);
										} else {
											let item = res.data.video[0]
											this.playing = true;
											this.currentVideo = item;

											this.currentSelectVideo = item;
											this.videosIndex = 0;
											//新加video.js
											this.$nextTick(() => {
												// eslint-disable-next-line no-undef
												this.player = videojs(
													"videos",
													this.options,
													function onPlayerReady() {
														this.maxDragProgress =
															item.task_time;
														if (
															item.video_type ==
															1
														) {
															this.src(
																{
																	src:
																		item.video,
																	type:
																		"video/mp4"
																}
															);
														} else {
															this.src(
																{
																	src:
																		item.video,
																	type:
																		"application/x-mpegURL"
																}
															);
														}

														this.src({
															src:
																item.video,
															type:
																"video/webm"
														});
														this.src({
															src:
																item.video,
															type:
																"video/ogg"
														});
														if (
															item.video_type !=
															1
														) {
															this.src(
																{
																	src:
																		item.video,
																	type:
																		"video/mp4"
																}
															);
														} else {
															this.src(
																{
																	src:
																		item.video,
																	type:
																		"application/x-mpegURL"
																}
															);
														}

														this.load({
															src:
																item.video
														});
														this.currentTime(
															item.task_time
														);
														this.play();

														// eslint-disable-next-line no-undef
														this.on(
															"loadedmetadata",
															function () { }
														);
														this.on(
															"ended",
															function () { }
														);
														this.on(
															"error",
															function () {
																this.errorDisplay.close();
															}
														);

														// var last =  this.maxDragProgress,max_time=this.maxDragProgress;
														//当目前的播放位置已更改时
														// this.on('timeupdate', function(){
														//     var current = this.currentTime();

														//     if(current < max_time){
														//       console.log(current,max_time)
														//         console.log("进入最大值")
														//     }else if(current - last > 2) {
														//         this.currentTime(last);
														//     } else {
														//         last = current;
														//         if(last >= max_time){
														//             max_time=last;
														//         }
														//     }

														//     if (_this.nextNode &&_this.nextNode.time &&_this.currentVideo.video_task != 100
														//   ) {
														//     if (Math.floor(_this.video_real_time) == Number(_this.nextNode.time)) {

														//       this.isVideo = true
														//       _this.playVideos()
														//       _this.useStopPlay(); // 非全屏时识别
														//     }
														//   }

														// });
													}
												);
											});
										}
									}
								});
						}
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.courseDetails {
	margin-bottom: 60px;
}

.c_D_back {
	background-color: #2f2f2f;

	.c_D_back_title {
		padding: 20px 0;

		p {
			color: #ffffff;
			font-size: 16px;
			font-family: "MicrosoftYaHei";
			border-left: 3px solid #ffffff;
			padding-left: 10px;
		}
	}
}

.c_D_back_video {
	.c_D_video_con {
		height: 51px;
		background-color: #000000;
		display: flex;
		align-items: center;
	}

	.yh-w100 {
		height: 675px;
	}
}

.classifyAct {
	font-weight: bold;
	color: #1678ff;

	span {
		line-height: 32px;
		border-bottom: 2px solid #1678ff;
	}
}

/deep/ .el-collapse-item__header {
	background: #f2f2f2;
	margin-bottom: 10px;
}

/deep/ .el-collapse-item__content {
	padding-bottom: 10px;
}

.actChapter /deep/ .el-collapse-item__header {
	background: rgba(22, 120, 255, 0.2);
}

video::-webkit-media-controls-fullscreen-button {
	display: none;
}

.plate-center {
	position: relative;

	.plate-center-photo {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0px;
		bottom: 80px;
		z-index: 0;

		.identify {
			width: 200px;
			height: 200px;
			// border-radius: 290px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: relative;
		}

		div {
			width: 200px;
			height: 200px;
			background: black;
			// border-radius: 290px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: relative;

			.canvas {
				width: 200px;
				height: 200px;
				// border-radius: 290px;
				opacity: 0;
				z-index: -1;
			}

			.video {
				width: 200px;
				height: 200px;
				// border-radius: 290px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				display: block;
			}
		}
	}

	.plate-center-photos {
		z-index: 2;
	}
}

.topic {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 686px;
	padding: 30px 40px;
	z-index: 2021;
	background: #ffffff;
	box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.4);
	border-radius: 10px;

	.topic_main {
		margin: 0 auto;
	}

	.topic_close {
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 20px;
		width: 26px;
		height: 26px;
	}

	.topic_main_name {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	.topic_main_daan {
		display: flex;
		padding: 20px 30px;
		border-bottom: 1px solid #ececec;

		span {
			color: #333333;
			font-size: 18px;

			&:last-child {
				color: #1678ff;
				font-size: 20px;
				font-weight: bold;
			}
		}
	}

	.topic_main_footer {
		margin-top: 50px;
		background: #f3f6f8;
	}

	.topic_main_jiexi {
		font-size: 14px;
		padding: 30px;
	}

	.topic_main_btn {
		display: flex;
		margin-top: 50px;
		justify-content: space-between;
		padding: 0 50px;

		.topic_main_btn_li {
			width: 200px;
			height: 48px;
			cursor: pointer;
			color: #ffffff;
			font-size: 16px;
			line-height: 48px;
			text-align: center;
			background: #1678ff;
			border-radius: 4px;
		}
	}

	.topic_main_list {
		li {
			margin-top: 40px;
			font-size: 20px;
		}

		.active {
			color: #1678ff;
		}
	}
}

.marking {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	position: fixed;
}
</style>
